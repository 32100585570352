<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="match-height">
        <b-col lg="12">
          <b-overlay
            :show="ShowRound"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-card>
              <b-row>
                <b-col lg="6">
                  <h3>เลือกหวย</h3>
                </b-col>
              </b-row>
              <!-- {{ ListSelect }} -->
              <b-form-group
                label="หวย"
                label-for="customDelimiter"
              >
                <v-select
                  v-model="LottoTypeHead"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="ListSelect"
                  :reduce="ele => ele.value"
                  @input="LottoSubHead = null ,SelectType()"
                />

              </b-form-group>
              <b-form-group
                v-if="LottoSubList"
                label="ประเภทหวย"
                label-for="customDelimiter"
              >
                <v-select
                  v-model="LottoSubHead"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="LottoSubList"
                  :reduce="ele => ele.value"
                  @input="SelectType(), ClearUserBar()"
                />
              </b-form-group>
              <b-row>
                <b-col
                  v-if="RoundData.length > 0"
                  lg="12"
                >
                  <b-form-group
                    label="งวดประจำวันที่"
                    label-for="customDelimiter"
                  >
                    <v-select
                      v-model="RoundID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="CloseDateName"
                      :options="RoundData"
                      :reduce="ele => ele._id"
                      @input="SelectRound(), ClearUserBar()"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-else-if="(LottoTypeHead && !LottoSubList) || LottoSubHead"
                  lg="12"
                >
                  <div class="alert alert-primary p-1 text-center">
                    <i class="fa fa-folder-open" /> ไม่พบรอบหวย
                  </div>

                </b-col>
              </b-row>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col
          v-if="((LottoTypeHead && !LottoSubList) || LottoSubHead) && RoundID"
          lg="12"
        >
          <b-overlay
            :show="ShowLog"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-card no-body>
              <div class="col-12">
                <div
                  id="__BVID__811"
                  class="tabs navtab-bg"
                >
                  <!-- <div class="">
                    <br>
                    <ul
                      id="__BVID__811__BV_tab_controls_"
                      role="tablist"
                      class="nav nav-tabs nav-justified"
                    >
                      <li
                        role="presentation"
                        class="nav-item"
                      >
                        <a
                          role="tab"
                          aria-selected="true"
                          aria-setsize="2"
                          aria-posinset="1"
                          href="#"
                          target="_self"
                          class="nav-link active"
                          :class="{'bg-primary text-white': type == false}"
                          aria-controls="__BVID__812"
                          @click="type = false"
                        >แยกตาม สมาชิก</a>
                      </li><li
                        role="presentation"
                        class="nav-item"
                      >
                        <a
                          role="tab"
                          tabindex="-1"
                          aria-selected="false"
                          aria-setsize="2"
                          aria-posinset="2"
                          href="#"
                          target="_self"
                          class="nav-link active"
                          :class="{'bg-primary text-white': type == true}"
                          aria-controls="__BVID__815"
                          @click="type = true"
                        >แยกตาม ประเภท</a>
                      </li>
                    </ul>
                  </div> -->
                  <br>
                  <div
                    id="__BVID__811__BV_tab_container_"
                    class="tab-content"
                  >
                    <div
                      id="__BVID__812"
                      role="tabpanel"
                      aria-hidden="false"
                      class="tab-pane active"
                      aria-labelledby="__BVID__812___BV_tab_button__"
                    >
                      <div class="row">
                        <div
                          v-if="type === false"
                          class="col-12"
                        >
                          <b-alert
                            variant="primary"
                            show
                          >
                            <div class="alert-body">
                              <a
                                v-for="(item, index) in UserArry"
                                :key="item._id"
                                aria-current="location"
                                class="mr-1 h5"
                                @click="
                                  RoleBack(item), DelUserArry(UserArry, index)
                                "
                              > <strong
                                v-if="index > 0"
                              ><i
                                class="fas fa-angle-right"
                                style="margin-top: 5px !important"
                              /></strong> <strong style="margin-top: -15px">{{ item.name }}</strong></a>
                            </div>
                          </b-alert>
                          <!-- {{ UserArry }} -->
                        </div>
                        <div
                          v-if="this.switch === false && type === false"
                          class="table-responsive col-12"
                        >
                          <table
                            class="
                          table
                          table-bordered
                          table-sm
                          table-hover
                          table-striped
                          tb-bd
                        "
                          >
                            <thead class="thead-dark">
                              <tr>
                                <th
                                  rowspan="2"
                                  class="text-center align-middle"
                                  style="width: 15rem"
                                >
                                  ชื่อเข้าใช้งาน
                                  <br>
                                </th>
                                <th
                                  rowspan="2"
                                  class="text-center align-middle"
                                >
                                  ระดับ
                                </th>
                                <th
                                  rowspan="2"
                                  class="text-center align-middle bd-r-w"
                                >
                                  ยอดทั้งหมด
                                </th>
                                <th
                                  colspan="3"
                                  class="text-center bd-r-w"
                                >
                                  สมาชิก
                                </th>
                                <th
                                  colspan="3"
                                  class="text-center bd-r-w"
                                >
                                  {{ UserArry[UserArry.length - 1].name }}
                                </th>
                                <th
                                  colspan="3"
                                  class="text-center"
                                >
                                  บริษัท
                                </th>
                              </tr>
                              <tr>
                                <th class="text-center">
                                  ส่งออก
                                </th>
                                <th class="text-center">
                                  คอม
                                </th>
                                <th class="text-center bd-r-w">
                                  รวม
                                </th>
                                <th class="text-center">
                                  ถือหุ้น
                                </th>
                                <th class="text-center">
                                  คอม
                                </th>
                                <th class="text-center bd-r-w">
                                  รวม
                                </th>
                                <th class="text-center">
                                  ถือหุ้น
                                </th>
                                <th class="text-center">
                                  คอม
                                </th>
                                <th class="text-center">
                                  รวม
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <!-- วน -->
                              <tr
                                v-for="item in BetReportData.Data"
                                :key="item"
                              >
                                <td>
                                  <button
                                    v-if="item.type === 'Member'"
                                    type="button"
                                    class="btn btn-link"
                                    @click="gethistory(item.name)"
                                  >
                                    {{ item.name }}
                                  </button>
                                  <button
                                    v-else
                                    type="button"
                                    class="btn btn-link"
                                    @click="
                                      GetBetByAgent(item.agent_id),
                                      UserArry.push({
                                        name: item.name,
                                        agent_id: item.agent_id,
                                        type: 'Agent',
                                      })
                                    "
                                  >
                                    {{ item.name }}
                                  </button>
                                </td>
                                <td class="text-center">
                                  <span
                                    v-if="item.type === 'Member'"
                                    class="badge badge-primary"
                                  >{{ item.type }}</span>
                                  <span
                                    v-else
                                    class="badge badge-success"
                                  >{{
                                    item.type
                                  }}</span>
                                </td>
                                <td class="text-right bd-r-w text-success">
                                  {{ Commas(item.total) }}
                                </td>
                                <td
                                  class="text-right text-danger"
                                >
                                  -{{ Commas(item.member_export) }}
                                </td>
                                <td
                                  class="text-right text-success"
                                >
                                  {{ Commas(item.member_com) }}
                                </td>
                                <td
                                  class="text-right bd-r-w text-danger"
                                >
                                  -{{ Commas(item.member_total) }}
                                </td>
                                <td
                                  class="text-right text-success"
                                >
                                  {{ Commas(item.agent_hold) }}
                                </td>
                                <td
                                  class="text-right text-danger"
                                >
                                  -{{ Commas(item.agent_com) }}
                                </td>
                                <td
                                  class="text-right bd-r-w text-success"
                                >
                                  {{ Commas(item.agent_total) }}
                                </td>
                                <td
                                  class="text-right text-success"
                                >
                                  {{ Commas(item.company_hold) }}
                                </td>
                                <td
                                  class="text-right text-danger"
                                >
                                  -{{ Commas(item.company_com) }}
                                </td>
                                <td
                                  class="text-right text-success"
                                >
                                  {{
                                    Commas(item.company_total)
                                  }}
                                </td>
                              </tr>
                              <tr class="tb-bd">
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  <div class="my-2">
                                    <b>รวม</b>
                                  </div>
                                </td>
                                <td
                                  class="
                                text-right
                                font-weight-bold
                                bd-r-w
                                text-success
                              "
                                >
                                  {{ Commas(BetReportData.totalAll) }}
                                </td>
                                <td class="text-right font-weight-bold text-danger">
                                  -{{ Commas(BetReportData.mem_exportAll) }}
                                </td>
                                <td
                                  class="text-right font-weight-bold text-success"
                                >
                                  {{ Commas(BetReportData.mem_comAll) }}
                                </td>
                                <td
                                  class="
                                text-right
                                font-weight-bold
                                bd-r-w
                                text-danger
                              "
                                >
                                  -{{ Commas(BetReportData.mem_totalAll) }}
                                </td>
                                <td
                                  class="text-right font-weight-bold text-success"
                                >
                                  {{ Commas(BetReportData.agent_holdAll) }}
                                </td>
                                <td class="text-right font-weight-bold text-danger">
                                  -{{ Commas(BetReportData.agent_comAll) }}
                                </td>
                                <td
                                  class="
                                text-right
                                font-weight-bold
                                bd-r-w
                                text-success
                              "
                                >
                                  {{ Commas(BetReportData.agent_totalAll) }}
                                </td>
                                <td
                                  class="text-right font-weight-bold text-success"
                                >
                                  {{ Commas(BetReportData.com_holdAll) }}
                                </td>
                                <td class="text-right font-weight-bold text-danger">
                                  -{{ Commas(BetReportData.com_comAll) }}
                                </td>
                                <td
                                  class="text-right font-weight-bold text-success"
                                >
                                  {{ Commas(BetReportData.com_totalAll) }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
                          v-if="this.switch === true && type === false"
                          class="table-responsive col-12"
                        >
                          <b-table
                            hover
                            responsive
                            class="position-relative items-center"
                            :per-page="perPageHis"
                            :items="itemsGame"
                            :fields="fieldsGame"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                          >
                            <template #cell(index)="data">
                              {{
                                perPageHis * (currentPageHis - 1) + (data.index + 1)
                              }}
                            </template>
                            <template #cell(status)="data">
                              <b-badge
                                :variant="
                                  data.item.status === 'confirm'
                                    ? 'success'
                                    : 'danger'
                                "
                                class="badge-glow"
                              >
                                {{
                                  data.item.status === "confirm"
                                    ? "ยืนยัน"
                                    : "ยกเลิก"
                                }}
                              </b-badge>
                            </template>
                            <template #cell(status_result)="data">
                              <b-badge
                                :variant="
                                  data.item.status_result === 'win'
                                    ? 'success'
                                    : data.item.status_result === 'waiting'
                                      ? 'info'
                                      : data.item.status_result === 'processing'
                                        ? 'warning'
                                        : 'danger'
                                "
                                class="badge-glow"
                              >
                                {{
                                  data.item.status_result === "win"
                                    ? "ถูกรางวัล"
                                    : data.item.status_result === "waiting"
                                      ? "รอผล"
                                      : data.item.status_result === "processing"
                                        ? "กำลังตรวจ"
                                        : "ไม่ถูกรางวัล"
                                }}
                              </b-badge>
                            </template>
                            <template #cell(LottoSubHead)="data">
                              {{
                                data.item.LottoSubHead
                                  ? NameLottoSubHead(
                                    data.item.LottoTypeHead,
                                    data.item.LottoSubHead
                                  )
                                  : "-"
                              }}
                            </template>
                            <!-- Column: Actions -->
                            <template #cell(actions)="data">
                              <div class="text-nowrap">
                                <feather-icon
                                  :id="`invoice-row-${data.item.id}-preview-icon`"
                                  icon="FileTextIcon"
                                  size="16"
                                  class="mx-1"
                                  @click="
                                    $router.push({
                                      name: 'dashboard',
                                      params: { id: data.item.id },
                                    })
                                  "
                                />
                                <b-tooltip
                                  title="History Deposit & Withdraw"
                                  :target="`invoice-row-${data.item.id}-preview-icon`"
                                />
                              </div>
                            </template>
                          </b-table>

                          <b-card-body
                            class="d-flex justify-content-between flex-wrap pt-0"
                          >
                            <!-- page length -->
                            <b-form-group
                              label="Per Page"
                              label-cols="8"
                              label-align="left"
                              label-size="sm"
                              label-for="sortBySelect"
                              class="text-nowrap mb-md-0 mr-1"
                            >
                              <b-form-select
                                id="perPageSelect"
                                v-model="perPageHis"
                                size="sm"
                                inline
                                :options="pageOptions"
                                @change="gethistory()"
                              />
                            </b-form-group>

                            <!-- pagination -->
                            <div>
                              <b-pagination
                                v-model="currentPageHis"
                                :total-rows="totalRowsGame"
                                :per-page="perPageHis"
                                first-number
                                last-number
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mb-0"
                                @input="gethistory()"
                              >
                                <template #prev-text>
                                  <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                  />
                                </template>
                                <template #next-text>
                                  <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                  />
                                </template>
                              </b-pagination>
                            </div>
                          </b-card-body>
                        </div>
                        <!-- แยกตามประเภท -->
                        <div
                          v-if="type === true"
                          class="col-12 table-responsive"
                        >
                          <table
                            class="table table-bordered table-sm table-hover mt-3 table-striped tb-bd"
                          >
                            <thead
                              class="thead-dark"
                            >
                              <tr>
                                <th
                                  rowspan="2"
                                  class="text-center  align-middle"
                                >
                                  ประเภท
                                </th>
                                <th
                                  rowspan="2"
                                  class="text-center  align-middle bd-r-w"
                                >
                                  ยอดทั้งหมด
                                </th>
                                <th
                                  colspan="3"
                                  class="text-center bd-r-w"
                                >
                                  สมาชิก
                                </th>
                                <th
                                  colspan="3"
                                  class="text-center bd-r-w"
                                >
                                  {{ UserArry[0].name }}
                                </th>
                                <th
                                  colspan="3"
                                  class="text-center"
                                >
                                  บริษัท
                                </th>
                              </tr>
                              <tr>
                                <th
                                  class="text-center"
                                >
                                  ส่งออก
                                </th>
                                <th
                                  class="text-center"
                                >
                                  คอม
                                </th>
                                <th
                                  class="text-center bd-r-w"
                                >
                                  รวม
                                </th>
                                <th
                                  class="text-center"
                                >
                                  ถือหุ้น
                                </th>
                                <th
                                  class="text-center"
                                >
                                  คอม
                                </th>
                                <th
                                  class="text-center bd-r-w"
                                >
                                  รวม
                                </th>
                                <th
                                  class="text-center"
                                >
                                  ถือหุ้น
                                </th>
                                <th
                                  class="text-center"
                                >
                                  คอม
                                </th>
                                <th
                                  class="text-center"
                                >
                                  รวม
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="itemss in BetReportTypeData.Res"
                                :key="itemss"
                              >
                                <td>
                                  <div
                                    class="mr-2 my-2"
                                  >
                                    <b>&nbsp;{{ itemss.name_type }}</b>
                                  </div>
                                </td>
                                <td
                                  class="text-right bd-r-w text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(itemss.total.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right text-danger"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    -{{ Commas(itemss.mem_export.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(itemss.mem_com.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right bd-r-w text-danger"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    -{{ Commas(itemss.mem_total.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(itemss.agent_hold.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right text-danger"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    -{{ Commas(itemss.agent_com.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right bd-r-w text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(itemss.agent_total.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(itemss.com_hold.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right text-danger"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    -{{ Commas(itemss.com_com.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(itemss.com_total.toFixed(2)) }}
                                  </div>
                                </td>
                              </tr>
                              <!-- รวม -->
                              <tr
                                class="tb-bd"
                              >
                                <td
                                  class="text-center "
                                >
                                  <div
                                    class="my-2"
                                  >
                                    <b>รวม </b>
                                  </div>
                                </td>
                                <td
                                  class="text-right  font-weight-bold bd-r-w text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(BetReportTypeData.totalAll.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right  font-weight-bold text-danger"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    -{{ Commas(BetReportTypeData.mem_exportAll.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right  font-weight-bold text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(BetReportTypeData.mem_comAll.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right  font-weight-bold bd-r-w text-danger"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    -{{ Commas(BetReportTypeData.mem_totalAll.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right  font-weight-bold text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(BetReportTypeData.agent_holdAll.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right  font-weight-bold text-danger"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    -{{ Commas(BetReportTypeData.agent_comAll.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right  font-weight-bold bd-r-w text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(BetReportTypeData.agent_totalAll.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right  font-weight-bold text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(BetReportTypeData.com_holdAll.toFixed(2)) }}
                                  </div>
                                </td>
                                <td
                                  class="text-right  font-weight-bold text-danger"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    -{{ Commas(BetReportTypeData.com_comAll.toFixed(2)) }}
                                  </div>
                                </td> <td

                                  class="text-right  font-weight-bold text-success"
                                >
                                  <div
                                    class="my-2"
                                  >
                                    {{ Commas(BetReportTypeData.com_totalAll.toFixed(2)) }}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      id="__BVID__815"
                      role="tabpanel"
                      aria-hidden="true"
                      class="tab-pane"
                      aria-labelledby="__BVID__815___BV_tab_button__"
                      style="display: none"
                    />
                  </div>
                </div>
              </div>
            </b-card>
          </b-overlay>
        </b-col>

      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BAlert,
  // BFormInput,
  // BButton,
  BPagination,
  BTable,
  // BInputGroupPrepend,
  BFormSelect,
  // BProgress,
  BBadge,
  // BTabs,
  // BTab,
  BOverlay,
  BIconController,

} from 'bootstrap-vue'
// import moment from 'moment-timezone'
import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
import moment from 'moment-timezone'
// import {
//   required, integer, between, length,
// } from '@validations'

export default {
  components: {
    vSelect,
    BAlert,
    // BInputGroupPrepend,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BTable,
    // ValidationProvider,
    ValidationObserver,
    // BProgress,
    BBadge,
    // BTabs,
    // BTab,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      TabActive: true,
      UserData: JSON.parse(localStorage.getItem('userData')),
      perPageHis: 10,
      currentPageHis: 1,
      itemsGame: [],
      totalRowsGame: 1,
      fieldsGame: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'list_id', label: 'โพยที่' },
        { key: 'RoundData.CloseDateName', label: 'งวดที่' },
        {
          key: 'status',
          label: 'สถานะแทง',
        }, // confirm , cancel
        {
          key: 'status_result',
          label: 'สถานะออกหวย',
        },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        {
          key: 'LottoHeadName',
          label: 'หวย',
          // formatter: value => this.NameLottoHead(value) || '-',
        },
        // { key: 'LottoSubHead', label: 'ประเภทหวย' },
        { key: 'bet', label: 'เลข' },
        { key: 'name_type', label: 'ประเภท' },
        {
          key: 'amount',
          label: 'ยอด',
          formatter: value => this.Commas(value),
        },
        { key: 'win_rate', label: 'จ่าย' },
        // {
        //   key: 'startbalance',
        //   label: 'ราคาก่อนแทง',
        //   formatter: value => this.Commas(value),
        // },
        {
          key: 'endbalance',
          label: 'เครดิตหลังแทง',
          formatter: value => this.Commas(value),
        },
        {
          key: 'created_at',
          label: 'เวลาแทง',
          formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      ShowRound: false,
      ShowResult: false,
      ShowLog: false,
      totalRows: 1,
      currentPage: 1,
      items: [],
      totalRowsLog: 1,
      currentPageLog: 1,
      itemsLog: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      fields: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'list_id', label: 'โพยที่' },
        {
          key: 'status',
          label: 'สถานะแทง',
        }, // confirm , cancel
        {
          key: 'status_result',
          label: 'สถานะออกหวย',
        },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        // {
        //   key: 'LottoHead',
        //   label: 'หวย',
        //   formatter: value => this.NameLottoHead(value) || '-',
        // },
        // { key: 'LottoSubHead', label: 'ประเภทหวย' },
        { key: 'bet', label: 'เลข' },
        { key: 'name_type', label: 'ประเภท' },
        { key: 'amount', label: 'ยอด', formatter: value => this.Commas(value.toFixed(2)) },
        { key: 'win_rate', label: 'จ่าย' },
        // {
        //   key: 'startbalance',
        //   label: 'ราคาก่อนแทง',
        //   formatter: value => this.Commas(value),
        // },
        {
          key: 'endbalance',
          label: 'เครดิตหลังแทง',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        {
          key: 'created_at',
          label: 'เวลาแทง',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      fieldsLog: [
        { key: 'index', label: 'ลำดับที่' },
        {
          key: 'status',
          label: 'สถานะให้รางวัล',
        }, // confirm , cancel
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'bet', label: 'แทงเลข' },
        { key: 'result_bet', label: 'เลขที่ออก' },
        { key: 'name_type', label: 'ประเภท' },
        { key: 'amount', label: 'ยอด', formatter: value => this.Commas(value.toFixed(2)) },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'reward', label: 'ผลรางวัล', formatter: value => this.Commas(value.toFixed(2)) },
        {
          key: 'startbalance',
          label: 'เครดิตก่อนให้รางวัล',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        {
          key: 'endbalance',
          label: 'เครดิตหลังให้รางวัล',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        {
          key: 'created_at',
          label: 'เวลาให้รางวัล',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      LottoTypeHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      ResultData: {},
      RoundData: [],
      RoundID: null,
      Interval: null,
      IntervalTotalPro: null,
      IntervalTotalLog: null,
      TotalBet: 0,
      TotalPro: 0,
      TotalReward: 0,
      TotalLog: 0,
      TotalWin: 0,
      TotalLose: 0,
      SumAmountALl: 0,
      SumAmountWin: 0,
      BetReportArry: null,
      BetReportMax: 0,
      BetReportData: null,
      his_username: '',
      switch: false,
      UserArry: [],
      BetReportTypeData: {},
      type: false,
      ListSelect: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  async created() {
    this.LottoList = await this.GetSublist()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.UserArry = []
    this.ClearUserBar()
    // this.Get_userlist()
    // this.getSMS()
  },
  methods: {
    ClearUserBar() {
      this.UserArry = []
      const OBJ = {
        name: this.UserData.agent_name,
        agent_id: this.UserData.agent_id,
        type: 'Agent',
      }
      this.UserArry.push(OBJ)
    },
    async RoleBack(data) {
      console.log(data)
      if (data.type === 'Member') {
        if (data.name !== this.his_username) {
          await this.ClearCal()
          this.switch = false
          this.gethistory(data.name)
        }
      } else {
        await this.ClearCal()
        this.switch = true
        this.GetBetByAgent(data.agent_id)
      }
    },
    DelUserArry(arry, index) {
      // console.log(arry, index)
      let i = 0
      while (i < arry.length) {
        if (i > index) {
          arry.splice(i, 1)
        } else {
          // eslint-disable-next-line no-plusplus
          ++i
        }
      }
    },
    ClearCal() {
      this.switch = false
      // this.UserArry = []
    },
    GetBet(RoundID) {
      this.ClearCal()
      this.ShowRound = true
      const params = {
        round_id: RoundID,
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/history/betreportBymember', params
          ,
        )
        .then(response => {
          this.ShowRound = false
          this.BetReportData = response.data
          this.Max = this.BetReportData.length
        })
        .catch(error => {
          console.log(error)
        })
    },
    GetHisType(RoundID) {
      this.BetReportTypeData = {}
      const params = {
        round_id: RoundID,
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/history/betpeportBymemberType', params
          ,
        )
        .then(response => {
          this.BetReportTypeData = response.data
          // this.Max = this.BetReportData.length
        })
        .catch(error => {
          console.log(error)
        })
    },
    GetBetByAgent(AgentID) {
      this.ClearCal()
      this.BetReportData = null
      // this.ShowRound = true
      const params = {
        // eslint-disable-next-line no-underscore-dangle
        round_id: this.RoundID,
        agent_id: AgentID,
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/history/betreportBymember', params
          ,
        )
        .then(response => {
          // this.ShowRound = false
          this.BetReportData = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    gethistory(username) {
      this.switch = true
      if (username) {
        this.his_username = username
        const OBJ = {
          name: username,
          type: 'Member',
        }
        this.UserArry.push(OBJ)
      }
      // const index = 0
      // this.ShowRound = true
      const params = {
        page: this.currentPageHis,
        perpage: this.perPageHis,
        // eslint-disable-next-line no-underscore-dangle
        round_id: this.RoundID,
      }
      this.$http
        .get(
          `https://api.rmtlotto.com/api/history/play_report/${this.his_username}`,
          { params },
        )
        .then(response => {
          // this.his_username = null
          this.onFilteredHis(response.data)
          // this.ShowRound = false
        })
        .catch(error => {
          // this.ShowRound = false
          console.log(error)
        })
    },
    onFilteredHis(filteredItems) {
      this.itemsGame = filteredItems.Data
      this.totalRowsGame = filteredItems.total
    },
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('https://api.rmtlotto.com/api/LottoList')
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              LottoTypeHead: Res[x].TypeID,
              Data: Res[x],
            },
          )
          this.ListSelect.push({
            text: Res[x].name,
            value: Res[x].TypeID,
          })
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    LottoStatus(Head, Sub, Val) {
      if (!Head) return false
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        if (this.LottoList[IndexHead].SubList && Sub) {
          const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
          if (IndexSub >= 0) {
            return this.LottoList[IndexHead].SubList[IndexSub].Data[Val] === 1
          }
        } else if (!this.LottoList[IndexHead].SubList) {
          return this.LottoList[IndexHead].Data[Val] === 1
        } else {
          return false
        }
      }
      return false
    },
    async SelectType() {
      // this.ClearValue()
      this.RoundID = null
      this.RoundData = []
      // console.log(this.LottoTypeHead)
      if (this.LottoTypeHead) {
        this.ShowRound = true
        await this.GetRound()
        this.ShowRound = false
      }
    },
    async SelectRound() {
      // this.ClearValue()
      this.ShowResult = true
      await this.GetBet(this.RoundID)
      this.ShowResult = false
      this.GetHisType(this.RoundID)
    },
    async IntervalGetResult() {
      this.Interval = setInterval(() => {
        this.GetResult(this.RoundID)
        if (this.ResultData.status === 'success') {
          clearInterval(this.Interval)
        }
      }, 30000)
    },
    async GetRound() {
      // console.log('GetRound')
      const params = {
        LottoTypeHead: this.LottoTypeHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/round/ShowByTypeIdAll',
          { params },
        )
        if (ResData.success) {
          this.RoundData = ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async IntervalGetCountLog() {
      this.IntervalTotalLog = setInterval(async () => {
        // eslint-disable-next-line no-underscore-dangle
        this.TotalLog = await this.GetCountLog(this.ResultData._id)
        if (this.TotalLog === this.TotalWin) {
          clearInterval(this.Interval)
          this.GetResult(this.RoundID)
          if (this.ResultData.status === 'success') {
            clearInterval(this.Interval)
          }
        }
      }, 5000)
    },
    async IntervalGetCountPro() {
      this.IntervalTotalPro = setInterval(async () => {
        this.TotalPro = (await this.GetCountBet(this.ResultData.RoundID, 'processing') - this.TotalBet) * -1
        if (this.TotalPro === this.TotalBet) {
          this.GetResult(this.RoundID)
        }
      }, 5000)
    },
    // eslint-disable-next-line consistent-return
    async GetCountBet(ID, Type) {
      const params = {
        ID,
        Type,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/result/count/betbystatus',
          { params },
        )
        if (ResData.success) {
          return ResData.mes
        }
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    // eslint-disable-next-line consistent-return
    async GetCountLog(ID) {
      const params = {
        ID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/reward/count/detailbyresult',
          { params },
        )
        if (ResData.success) {
          return ResData.mes
        }
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    NameLottoHead(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      return this.LottoList[Index].text
    },
    NameLottoSubHead(Head, Sub) {
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
        if (IndexSub >= 0) {
          return this.LottoList[IndexHead].SubList[IndexSub].text
        }
      }
      return '-'
    },
    FindSublist(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      this.LottoSubList = this.LottoList[Index].SubList
    },
    ClearValue() {
      this.TotalPro = 0
      this.TotalBet = 0
      this.TotalReward = 0
      this.TotalLog = 0
      this.ResultData = {}
      clearInterval(this.Interval)
      clearInterval(this.IntervalTotalPro)
      clearInterval(this.IntervalTotalLog)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.Data
      this.totalRows = filteredItems.total
    },
    Calpercen(partialValue, totalValue) {
      return ((100 * partialValue) / totalValue).toFixed(2)
      // return (100 * partialValue) / totalValue
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid #ddd; */
}
thead th {
  background-color: #746d94 !important;
  color: #fff;
  text-align: center;
}
thead th {
  padding: 1rem !important;
}

tbody {
  background-color: #f1e8e8;
}

caption {
  padding: 10px;
  caption-side: bottom;
}

/* table {
    border-collapse: collapse;
    letter-spacing: 1px;
  } */
/* th,
  td {
    text-align: left;
    padding: 8px;
  }*/

tr:nth-child(even) {
  background-color: #f2f2f2;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 5px 10px;
}

td {
  color: #000;
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
